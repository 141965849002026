<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="formData" label-width="80px" :rules="rules">
      <el-form-item prop="account" label="登录账号">
        <el-input size="small" v-model="formData.account" placeholder="登录账号"></el-input>
      </el-form-item>
      <el-form-item prop="password" label="登录密码">
        <el-input size="small" v-model="formData.password" placeholder="登录密码"></el-input>
      </el-form-item>
      <el-form-item prop="username" label="姓名">
        <el-input size="small" v-model="formData.username" placeholder="姓名"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input size="small" v-model="formData.phone" placeholder="手机号"></el-input>
      </el-form-item>
      <el-form-item prop="role_ids" label="角色">
        <SelectRole style="width: 500px" :multiple="true" v-model="formData.role_ids"></SelectRole>
      </el-form-item>
      <el-form-item label="状态">
        <el-select size="small" v-model="formData.status" placeholder="请选择">
          <el-option label="正常" :value="20"></el-option>
          <el-option label="停用" :value="10"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="是否超管">
        <el-select disabled size="small" v-model="formData.is_super" placeholder="请选择">
          <el-option label="是" :value="1"></el-option>
          <el-option label="否" :value="0"></el-option>
        </el-select>
      </el-form-item> -->

      <el-form-item label=" ">
        <el-button size="small" type="" @click="$router.back()">取消</el-button>
        <el-button size="small" type="primary" @click="save">保存</el-button>
      </el-form-item>
    </el-form>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import SelectRole from '@/views/components/select-role.vue'
import { addAdmin, editAdmin, getAdminDetail, getRoleList } from './api'
export default {
  name: 'Detail',
  components: { SelectRole },
  data() {
    return {
      formData: {
        account: '',
        password: '',
        username: '',
        phone: '',
        role_ids: [],
        is_super: 0,
        status: 20
      },
      rules: {
        account: { required: true, message: '登录不能为空', trigger: 'blur' },
        username: { required: true, message: '姓名不能为空', trigger: 'blur' },
        phone: [
          { required: true, message: '手机号码为空', trigger: 'blur' },
          { pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/, message: '请输入合法手机号/电话号', trigger: 'blur' }
        ],
        role_ids: [{ required: true, message: '角色不能为空', trigger: 'blur' }]
      }
    }
  },

  mounted() {
    if (this.$route.params.id) this.getAdminDetail()
  },

  methods: {
    save() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (this.formData.id) {
            // 编辑
            await editAdmin(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            // 新增
            await addAdmin(this.formData).then(() => {
              this.$message.success('新增成功')
              this.$router.back()
            })
          }
        } else {
          return false
        }
      })
    },
    async getAdminDetail() {
      this.formData = await getAdminDetail(this.$route.params.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .el-form /deep/ {
    .el-form-item {
      .el-form-item__content {
        width: 500px;
        .el-cascader,
        .el-select {
          width: 100%;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.el-form /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
